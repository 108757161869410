@media (min-width: 768px) {
    .about-view .app-bg-header,
    .privacy-view .app-bg-header {
        background-image: url('./header-background.png');
        background-position: center right;
        opacity: 0.6;
    }
}

.about-view .splash-up {
    left: 0;
    top: -400px;
    z-index: -1;
    opacity: 0.6;
    background-image: url('./splash.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    height: 500px;
}

.about-view .splash-down {
    left: 0;
    bottom: -400px;
    z-index: -1;
    opacity: 0.6;
    background-image: url('./splash.png');
    background-repeat: no-repeat;
    background-position: left bottom;
    height: 500px;
    transform: scaleX(-1) scaleY(-1);
}
