@media (min-width: 768px) {
    .home-main-view header .app-bg-header {
        background-image: url('./header-background.jpg');
    }

    .home-main-view header .header-content {
        text-shadow: 5px 8px 0 rgba(0, 0, 0, 0.05);
    }

    .home-main-view header .navbar .container {
        border-bottom: 1px solid transparent;
        border-image: linear-gradient(
            100deg,
            rgba(255,255,255, 0.4),
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0)
        );
        border-image-slice: 1;
    }
}

.home-main-view header .btn-outline-light:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
}

.home-main-view header .btn-outline-light:active {
    color: var(--app-color-2) !important;
}
